import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { RegisterCompletionComponent } from './login/register-completion.component';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { AuthGuard } from './_guards/auth.guard';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'completion', component: RegisterCompletionComponent },
      { path: 'password', component: ForgotPasswordComponent },
      // {
      //   path: 'mypage',
      //   loadChildren: 'app/mypage/mypage.module#MypageModule',
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'avalue-mypage',
        loadChildren: 'app/avalue-mypage/mypage.module#MypageModule',
        canActivate: [AuthGuard]
      },
      { path: 'maintenance', component: MaintenanceComponent },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: '**', redirectTo: 'login', pathMatch: 'full' }
    ])
  ],
  declarations: [
  ],
  exports: [
    RouterModule
  ]
})
export class AppRouterModule { }
