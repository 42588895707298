import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})

export class MaintenanceComponent implements OnInit {
  constructor() { }
  public compoName = "Maintenance";
  ngOnInit() { }
}